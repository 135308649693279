/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import './Popup/Popup.css';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import $ from 'jquery';

const Popup = ({ popupData }) => {
    let current = useTranslation().i18n.language;
    const { t } = useTranslation();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 5000,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
    };

    return (
        <div className="modal fade" id="main_modal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content modal-lg">
                    <div className="modal-header modal-lg" style={{padding:'10px 15px'}}>
                       <span style={{fontSize:'1.2rem',float:'right', cursor:'pointer'}} data-dismiss="modal">&#10006;</span>
                    </div>
                    <div className="modal-body model-lg" style={{padding:0, overflowX:'hidden', overflowY:'auto'}}>
                        <div id="myCarousel2" className="carousel slide" data-ride="carousel">
                            {
                                popupData.isSlide ?
                                    <>
                                        <div className="carousel-inner">
                                            <div className="item active">
                                                <div className="sliderr2">
                                                    {
                                                        <Slider {...settings}>
                                                            <div className="imgContainer">
                                                                <a target='_blank'  href="https://events.icapital.biz/landing-page/investor-day-2024/">
                                                                    <img src={t('popup.image3')} style={{width:'100%', height:'auto', margin:'0 auto'}} />
                                                                </a>
                                                            </div>
                                                            <div className="imgContainer">
                                                                <a target='_blank'  href="https://events.icapital.biz/landing-page/why-investing-in-asean-is-attractive/">
                                                                    <img src={t('popup.image1')} style={{width:'100%', height:'auto', margin:'0 auto'}} />
                                                                </a>
                                                            </div>
                                                            <div className="imgContainer">
                                                                <a target='_blank'  href="https://bvia.icapital.biz/">
                                                                    <img src={t('popup.image2')} style={{width:'100%', height:'auto', margin:'0 auto'}} />
                                                                </a>
                                                            </div>
                                                            <div className="imgContainer">
                                                                <a target='_blank'  href="https://events.icapital.biz/event/university-event/2024-i-capital-talent-show/">
                                                                    <img src={t('popup.image4')} style={{width:'100%', height:'auto', margin:'0 auto'}} />
                                                                </a>
                                                            </div>
                                                            {/* <div className="imgContainer">
                                                                <a target='_blank'  href="https://events.icapital.biz/landing-page/2023-investor-day-of-icapitalbiz-berhad/">
                                                                    <img src={t('popup.image2')} style={{width:'100%', height:'auto', margin:'0 auto'}} />
                                                                </a>
                                                            </div> */}
                                                            {/* <div className="imgContainer">
                                                               	<a target="_blank" href="https://www.capitaldynamics.com.au/i-capital-asean-btb-fund/overview/"> 
								                                	<video width="100%" height="auto" controls autoPlay muted>	
								                                		<source src="https://mediafiles.capitaldynamics.biz/videos/marketing/icanbf-mkt-720p.mp4" type="video/mp4"/>
								                                		Your browser does not support video.
								                                	</video>
								                                </a>
                                                            </div> */}
                                                        </Slider>
                                                    }
                                                </div >
                                            </div>
                                        </div>
                                    </> :
                                    <div className="carousel-inner">
                                        <div className="item active">
                                            <a href={current === 'en' ? popupData.engurl : popupData.churl} target="blank"><img id="banner_img" src={current === 'en' ? popupData.engurl : popupData.churl} width="100%" /></a>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
};

// Popup.defaultProps = {
//     engurl: "https://webfiles.icapital.biz/banners/fake-news-27052021-full.jpg",
//     churl: "https://webfiles.icapital.biz/banners/fake-news-27052021-full.jpg",
//     isSlide: true,
// };
export default Popup;
